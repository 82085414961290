import React from 'react';
import { keyframes, styled } from 'styled-components';

import {
  AtlasBodyMain,
  AtlasBodyTitle,
  AtlasCaption,
  Icons,
  NeutralButtonAtom,
  StackAtom,
} from '../../atoms';
import { MenuItem } from '../../organisms/NavigationMenu/helpers';
import { IconButtonMolecule } from '../IconButton.molecule';
import { Icon } from '../../atoms/icons/Icon';
import { AtlasColor } from '../../foundations/Colors.foundations';
import { useTranslation } from '../../i18n';

interface AppMenuProps {
  userName: string;
  userEmail: string;
  countryName: string;
  warehouseName: string;
  version: string;
  menuItems: MenuItem[];
  lang: string;
  onLogout: () => void;
  onClose?: () => void;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MenuContainer = styled.div`
  position: fixed;
  background: ${AtlasColor.Background};
  overflow: auto;
  top: 62px;
  right: 0;
  padding: 16px;
  height: calc(100% - 60px);
  z-index: 1001;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
  justify-content: center;
`;

const LogoutButton = styled(NeutralButtonAtom)`
  padding: 16px;
  width: 100%;
  height: 56px;
  text-transform: none;
`;

export const AppMenuMolecule = (props: AppMenuProps) => {
  const { userName, userEmail, countryName, warehouseName, version, menuItems, lang, onLogout } =
    props;
  const { t } = useTranslation(lang);

  const onSelectItem = (item: MenuItem) => {
    window.location.href = item.url;
  };

  return (
    <MenuContainer>
      <StackAtom gap={24}>
        <StackAtom gap={12}>
          <AtlasBodyTitle>{t('navMenu.greeting', { username: userName })}</AtlasBodyTitle>
          <AtlasBodyMain>{userEmail}</AtlasBodyMain>
          <AtlasCaption>
            {t('navMenu.info', {
              countryName: t(`navMenu.${countryName.toLowerCase()}`),
              warehouseName,
              version,
            })}
          </AtlasCaption>
        </StackAtom>

        <OptionsContainer>
          {menuItems.map((menuItem, index) => (
            <IconButtonMolecule
              type="button"
              key={index}
              neutral={!menuItem.selected}
              secondary={menuItem.selected}
              src={(Icons as any)[menuItem.icon]}
              style={{ width: '97px', height: '97px' }}
              iconSize={24}
              onClick={() => {
                onSelectItem(menuItem);
              }}
            >
              <AtlasCaption>{t(`navMenu.${menuItem.item}`)}</AtlasCaption>
            </IconButtonMolecule>
          ))}

          <LogoutButton type="button" onClick={onLogout}>
            <Icon size={24} src={Icons.IcLogout} color="white" style={{ marginRight: '12px' }} />
            {t('navMenu.logout')}
          </LogoutButton>
        </OptionsContainer>
      </StackAtom>
    </MenuContainer>
  );
};
