export const translationsES = {
  navMenu: {
    greeting: '¡Vamos con todo, {{username}}!',
    info: '{{countryName}} | {{warehouseName}} | Versión {{version}}',
    mx: 'México',
    br: 'Brasil',
    pe: 'Perú',
    logout: 'Cerrar sesión',
    universalSearch: 'Buscador',
    providers: 'Proveedores',
    transfers: 'Transferencias',
    storage: 'Almacenaje',
    lift: 'Montacarguista',
    restock: 'Alertas de reabasto',
    translation: 'Traslado',
    inventoryFix: 'Corregir inventario',
    inverseLogistic: 'Logística inversa',
    picking: 'Picking',
    cycleCounting: 'Conteo cíclico',
    waste: 'Reporte de merma',
    pickingTransfer: 'Transferencias de inventario',
    inventoryTransfer: 'Transferencias de inventario',
    closeOrder: 'Cierre de orden',
    orderList: 'Listado de órdenes',
  },
};
