import { useNavigate } from 'react-router-dom'

export const useMenuPageNavigator = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(MENU_URL)
    }
  }
}

export const MENU_URL = '/menu'
