import { LoggedUserDTO } from '@justomx/webview-commons'
import { loginKeycloackByPassword } from './user-keycloak.client'

type UserPasswordProps = {
  username: string
  password: string
  country: string
}

export const loginUserKeycloackService = async ({username, password, country}: UserPasswordProps): Promise<LoggedUserDTO> => {
  const loginResponse = await loginKeycloackByPassword({
    username,
    password,
    country
  })

  return {
    country: loginResponse.country,
    authorizationCore: loginResponse.token,
    authorizationWms: loginResponse.token,
    authorization: loginResponse.token,
    refreshToken: loginResponse.refreshToken,
    firstName: loginResponse.userInfo.firstName,
    lastName: loginResponse.userInfo.lastName,
    roles: loginResponse.userInfo.roles,
    warehouse: loginResponse.warehouse,
    username: loginResponse.userInfo.username,
    menuItems: loginResponse.userInfo.menuItems
  }
}