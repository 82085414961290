type IsKeyValuePair = {
  key: string;
  value: string;
}

function isKeyValuePair(value: any): value is IsKeyValuePair {
  return typeof value === 'object' && value !== null && 'key' in value && 'value' in value;
}

function isKeyValuePairArray(value: any): value is IsKeyValuePair[] {
  return Array.isArray(value) && value.every(isKeyValuePair);
}


type MenuItem = {
  "item": string,
  "url": string,
  "icon": string
}

export interface LoggedUserDTO {
  country: string;
  authorizationCore: string;
  authorizationWms: string;
  authorization: string;
  warehouse: string;
  username: string;
  roles: string[];
  firstName: string;
  lastName: string;
  refreshToken?: string;
  menuItems?: MenuItem[];
}

export class LoggedUser implements LoggedUserDTO {
  public readonly country: string;
  public readonly authorizationCore: string;
  public readonly authorizationWms: string;
  public readonly authorization: string;
  public readonly warehouse: string;
  public readonly username: string;
  public readonly roles: string[];
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly refreshToken?: string;
  public readonly menuItems?: MenuItem[];

  constructor(dto: LoggedUserDTO) {
    this.country = dto.country;
    this.authorizationCore = dto.authorizationCore;
    this.authorizationWms = dto.authorizationWms;
    this.authorization = dto.authorization;
    this.warehouse = dto.warehouse;
    this.username = dto.username;
    this.roles = dto.roles;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.refreshToken = dto.refreshToken;
    this.menuItems = dto.menuItems;
  }

  toHeaders(): Record<string, string> {
    return {
      'x-justo-country': this.country,
      'x-authorization-core': this.authorizationCore,
      'x-authorization-wms': this.authorizationWms,
      'authorization': this.authorization,
      'x-justo-warehouse': this.warehouse,
      'x-justo-username': this.username,
      'x-justo-refresh-token': this.refreshToken || '',
    };
  }


  serialize(): string {
    const objectToSerialize = {
      headers: [
        {key: "country", value: this.country},
        {key: "authorizationCore", value: this.authorizationCore},
        {key: "authorizationWms", value: this.authorizationWms},
        {key: "authorization", value: this.authorization},
        {key: "warehouse", value: this.warehouse},
        {key: "username", value: this.username},
        {key: "firstName", value: this.firstName},
        {key: "lastName", value: this.lastName},
        {key: "roles", value: this.roles.join(',')},
        {key: "refreshToken", value: this.refreshToken || ''},
        {key: "menuItems", value: LoggedUser._serializeMenuItems(this.menuItems)}
      ]
    }
    return JSON.stringify(objectToSerialize);
  }

  static deserialize(serialized: string): LoggedUser {
    const parsed = JSON.parse(serialized.replaceAll('\\', ''));
    if (!parsed) throw new Error('Error parsing user: empty');
    if (parsed.headers && Array.isArray(parsed.headers) && parsed.headers.length > 0) {
      const headers = parsed.headers;
      if (isKeyValuePairArray(headers)) {
        const menuItemsStr = headers.find((header) => header.key === 'menuItems');
        return new LoggedUser({
          authorization: headers.find((header) => header.key === 'authorization')?.value || '',
          authorizationCore: headers.find((header) => header.key === 'authorizationCore')?.value || '',
          authorizationWms: headers.find((header) => header.key === 'authorizationWms')?.value || '',
          country: headers.find((header) => header.key === 'country')?.value || '',
          roles: headers.find((header) => header.key === 'roles')?.value.split(',') || [],
          username: headers.find((header) => header.key === 'username')?.value || '',
          warehouse: headers.find((header) => header.key === 'warehouse')?.value || '',
          firstName: headers.find((header) => header.key === 'firstName')?.value || '',
          lastName: headers.find((header) => header.key === 'lastName')?.value || '',
          refreshToken: headers.find((header) => header.key === 'refreshToken')?.value || '',
          menuItems: menuItemsStr?.value ? this._deserializeMenuItems(menuItemsStr?.value) : []
        });
      }
    }

    throw new Error('Error parsing user: headers malformed or not found. Try logging out and logging in again.');
  }

  /**
   * Why don't we use the JSON.stringify method?
   * Because the android's webview bridge adds backslashes to the string.
   * This breaks the deserialization when you want to use objects as values.
   */
  private static _serializeMenuItems(menuItems: MenuItem[] | undefined) {
    if(!menuItems) return '';
    else return menuItems.map((item) => `${item.item}|${item.icon}|${item.url}`).join(',');
  }

  private static _deserializeMenuItems(menuItems: string) {
    if(!menuItems) return [];
    else return menuItems.split(',').map((item) => {
      const [itemStr, iconStr, urlStr] = item.split('|');
      return {item: itemStr, icon: iconStr, url: urlStr};
    });
  }
}
