import styled from 'styled-components';
import React from 'react';

import { SVGComponent } from '../../@types/Svg.component';
import { AtlasColor } from '../../foundations/Colors.foundations';
import { AtlasBodySubtitle, AtlasBodyTitle, Icons, ProgressBar } from '../../atoms';
import { FlexRowAtom } from '../../atoms/FlexRow.atom';
import { Icon } from '../../atoms/icons/Icon';

const ToolbarComponentStyle = styled.div`
  flex-grow: 0;
  height: 56px;
  background-color: ${AtlasColor.Background};
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  justify-content: center;
  box-shadow: ${AtlasColor.BoxShadow};
`;

const ToolbarTitle = styled.div`
  flex-grow: 1;
  padding-left: 16px;
`;

const SecondaryLabel = styled(AtlasBodySubtitle)`
  margin-right: 8px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface ToolbarComponentProps {
  'title': string;
  'isMenuOpen'?: boolean;
  'secondaryActionIcon'?: SVGComponent;
  'thirdActionIcon'?: SVGComponent;
  'onAction'?: () => void;
  'onSecondaryAction'?: () => void;
  'onThirdAction'?: () => void;
  'progress'?: number;
  'data-test'?: string;
  'secondaryLabel'?: string;
}

export const AppToolbarMolecule = (props: ToolbarComponentProps) => {
  const {
    title,
    secondaryActionIcon,
    thirdActionIcon,
    onAction,
    onSecondaryAction,
    onThirdAction,
    progress,
    secondaryLabel,
    isMenuOpen,
  } = props;
  return (
    <ToolbarComponentStyle data-test={props['data-test']}>
      <Icon
        src={isMenuOpen ? Icons.CloseIcon : Icons.HamburgerIcon}
        onClick={onAction}
        data-test={props['data-test'] + '-action-icon'}
      />
      <ToolbarTitle>
        <AtlasBodyTitle data-test={props['data-test'] + '-title'}>
          {isMenuOpen ? 'Menú' : title}
        </AtlasBodyTitle>
      </ToolbarTitle>

      <FlexRowAtom $gap={16} $alignItems="center">
        {secondaryLabel && (
          <SecondaryLabel data-test={props['data-test'] + '-subtitle'}>
            {secondaryLabel}
          </SecondaryLabel>
        )}
        {secondaryActionIcon && (
          <Icon
            src={secondaryActionIcon}
            onClick={onSecondaryAction}
            data-test={props['data-test'] + '-secondary-action-icon'}
          />
        )}
        {thirdActionIcon && (
          <Icon
            src={thirdActionIcon}
            onClick={onThirdAction}
            data-test={props['data-test'] + '-third-action-icon'}
          />
        )}
      </FlexRowAtom>
      {progress !== undefined && (
        <ProgressBar progress={progress || 0} data-test={props['data-test'] + '-progress-bar'} />
      )}
    </ToolbarComponentStyle>
  );
};
