import { Country } from '../../internal-types/country.type'
import { isProduction } from '../../utils/environment'
import { LoggedUserDTO, UserManagment } from '@justomx/webview-commons'

const REDIRECT_URLS = {
  production: 'https://atlas-web.pages.dev/picking',
  staging: 'https://staging.atlas-web.pages.dev/picking'
}

export const saveAndRedirectService = async ({
  value,
  country,
  goToMenu
}:{value: LoggedUserDTO, country: Country, goToMenu: () => void}
): Promise<void> => {
  UserManagment.setCurrentUser(
    {
      ...value,
      country: value.country.toLowerCase()
    })
  if (!value.menuItems || !value.menuItems.length) {
    if (isProduction()) {
      window.location.href = REDIRECT_URLS.production + '?userFrom=getUserInfo&country=' + country
    } else {
      window.location.href = REDIRECT_URLS.staging + '?userFrom=getUserInfo&country=' + country
    }
    return;
  }

  if ('_justoPluginCurrentUser' in window && window._justoPluginCurrentUser) {
    console.log("Usuario logueado con éxito! Ahora puedes ingresar a otros sitios.")
  }

  goToMenu();
}
