import styled, { css } from 'styled-components';
import { SecondaryButtonAtom } from '../atoms/SecondaryButton.atom';
import { NeutralButtonAtom, PrimaryButtonAtom } from '../atoms';
import { Icon } from '../atoms/icons/Icon';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';

export interface IconButtonMoleculeProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  src: string;
  children?: ReactNode;
  secondary?: boolean;
  neutral?: boolean;
  iconSize?: number;
}

const iconButtonStyles = css`
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  height: 100%;
  text-transform: none;
`;

const StyledPrimaryButton = styled(PrimaryButtonAtom)`
  ${iconButtonStyles}
`;

const StyledSecondaryButton = styled(SecondaryButtonAtom)`
  ${iconButtonStyles}
`;

const StyledNeutralButton = styled(NeutralButtonAtom)`
  ${iconButtonStyles}
`;

export const IconButtonMolecule = ({
  src,
  children,
  secondary = false,
  neutral = false,
  iconSize = 20,
  ...props
}: IconButtonMoleculeProps) => {
  const ButtonComponent = secondary
    ? StyledSecondaryButton
    : neutral
      ? StyledNeutralButton
      : StyledPrimaryButton;

  return (
    <ButtonComponent {...props}>
      <Icon size={iconSize} src={src} color={neutral ? 'white' : undefined} />
      {children}
    </ButtonComponent>
  );
};
