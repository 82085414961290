import styled from 'styled-components'
import React from "react";

export interface IconParams {
  size?: number
  color?: 'white' | 'yellow'
  radius?: number
}

export const Icon =
  styled.img<IconParams>`
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  border-radius: ${({radius}) => radius}px;
  ${({color}) => color === 'white' ? 'filter: brightness(1000%)' : ''}
`

