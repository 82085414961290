import { newTokenAxiosInstance } from '../../utils/new-token.axios.instance'

type UserPasswordProps = {
  username: string
  password: string
  country: string
}

export async function loginKeycloackByPassword({
                                                 username,
                                                 password,
                                                 country
                                               }: UserPasswordProps) {
  const { data } = await newTokenAxiosInstance().post(
    '/user-manager-bff/api/login',
    {
      username,
      password,
      legacy: false
    },
    {
      headers: {
        'x-justo-country': country.toUpperCase() // Hay que cambiarlo por el country elegido
      }
    }
  )
  const accessToken = data.token.split('.')[1]
  // Convert the base64 encoded string to a string
  const decoded = Buffer.from(accessToken, 'base64').toString('utf8')
  const payload = JSON.parse(decoded)
  const userInfo = {
    firstName: payload.given_name,
    lastName: payload.family_name,
    username: payload.preferred_username,
    roles: data.roles,
    resourceAccess: payload.resource_access,
    menuItems: data.menuItems
  }

  let warehouse = data.user.warehouse?.name;
  if (!warehouse) {
    throw new Error("El usuario no posee una bodega configurada");
  }

  return {
    userInfo,
    token: data.token,
    refreshToken: data.refreshToken,
    roles: data.roles,
    warehouse,
    country: country.toUpperCase()
  }
}