export const translationsPT = {
  navMenu: {
    greeting: 'Vamos com tudo, {{username}}!',
    info: '{{countryName}} | {{warehouseName}} | Versão {{version}}',
    mx: 'México',
    br: 'Brasil',
    pe: 'Peru',
    logout: 'Sair',
    universalSearch: 'Busca',
    providers: 'Fornecedores',
    transfers: 'Transferências',
    storage: 'Armazenagem',
    lift: 'Operador de empilhadeira',
    restock: 'Alertas de reabastecimento',
    translation: 'Transporte',
    inventoryFix: 'Corrigir inventário',
    inverseLogistic: 'Logística reversa',
    picking: 'Separação',
    cycleCounting: 'Contagem cíclica',
    waste: 'Relatório de desperdício',
    pickingTransfer: 'Transferências de inventário',
    inventoryTransfer: 'Transferências de inventário',
    closeOrder: 'Fechamento de pedido',
    orderList: 'Lista de pedidos',
  },
};
